import React, { Component } from "react";
import RightMenu from "./RightMenu";

class Navbar extends Component {
  state = {
    current: "mail",
    visible: false
  };
  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  render() {
    return (
      <nav className="menuBar">
        <div className="logo">
          <a href="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="286"
              height="26"
              viewBox="0 0 286 26"
            >
              <text
                id="Pindai_Communications"
                data-name="Pindai Communications"
                transform="translate(0 21)"
                fill="#23ad46"
                fontSize="20"
                fontFamily="SFMono-Medium, SF Mono"
                fontWeight="500"
              >
                <tspan x="0" y="0">
                  Pindai Communications
                </tspan>
              </text>
            </svg>
          </a>
        </div>
        <div className="menuCon">
          <div className="rightMenu">
            <RightMenu />
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
