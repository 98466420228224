import React, { Component } from "react";
import { Row, Col, Typography } from "antd";
import AOS from "aos";
import {
  LinkedinFilled,
  InstagramFilled,
  FacebookFilled
} from "@ant-design/icons";

class FooterMobile extends Component {
  componentDidMount = () => {
    AOS.init({
      duration: 1000,
      delay: 50
    });
  };
  render() {
    return (
      <Row className="section9-mobile">
        <Col md={24}>
          <Typography className="footer1-mobile">Klik Bukan Klip</Typography>
        </Col>
        <Col md={8}>
          <div className="logo2-mobile">
            <a href="" style={{ paddingBottom: "1rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="286"
                height="26"
                viewBox="0 0 286 26"
              >
                <text
                  id="Pindai_Communications"
                  data-name="Pindai Communications"
                  transform="translate(0 21)"
                  fill="#23ad46"
                  fontSize="20"
                  fontFamily="SFMono-Medium, SF Mono"
                  fontWeight="500"
                >
                  <tspan x="0" y="0">
                    Pindai Communications
                  </tspan>
                </text>
              </svg>
            </a>
            <Typography style={{ color: "white" }}>
              021.7703759 | 021.7703759
              <br />
              pindai.mk@pindai.co.id
              <br />
              Boulevard Grand Depok City Blok D1 No 12A, Depok Jawa Barat 16412
            </Typography>
            <a href="">
              <LinkedinFilled
                style={{
                  color: "white",
                  paddingTop: "1rem",
                  fontSize: "4vw",
                  paddingBottom: "1rem"
                }}
              />
            </a>
            <a href="">
              <InstagramFilled
                style={{
                  paddingLeft: "2rem",
                  paddingRight: "3rem",
                  color: "white",
                  paddingTop: "1rem",
                  width: "6vw",
                  fontSize: "4vw",
                  paddingBottom: "1rem"
                }}
              />
            </a>
            <a href="">
              <FacebookFilled
                style={{
                  color: "white",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  fontSize: "4vw"
                }}
              />
            </a>
          </div>
        </Col>
      </Row>
    );
  }
}
export default FooterMobile;
