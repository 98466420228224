import React, { Component } from "react";
import { Row, Col, Typography } from "antd";
import AOS from "aos";
import Slider from "react-slick";

class Section1 extends Component {
  state = {
    width: window.innerWidth,
    visible: false,
    confirmLoading: false
  };
  componentDidMount = () => {
    AOS.init({
      duration: 1000,
      delay: 50
    });
  };

  handleOk = () => {
    this.setState({
      confirmLoading: true
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false
      });
    }, 2000);
  };
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  render() {
    const settings = {
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: false
    };
    return (
      <div>
        <Slider
          {...settings}
          style={{
            padding: 0,
            width: "100%",
            maxHeight: "560px"
          }}
        >
          <div>
            <img
              style={{ width: "100%", maxHeight: "560px", objectFit: "cover" }}
              alt="1"
              src="https://static.wixstatic.com/media/3524f7_c253a5e1122341d79f1a9110a9f94bdd~mv2.jpg/v1/fill/w_747,h_494,al_c,q_90,usm_0.66_1.00_0.01/3524f7_c253a5e1122341d79f1a9110a9f94bdd~mv2.webp"
            />
          </div>
          <div>
            <img
              style={{ width: "100%", maxHeight: "560px", objectFit: "cover" }}
              alt="1"
              src="https://static.wixstatic.com/media/3524f7_7b539889408d408f865aab840eff6b30.jpg/v1/fill/w_878,h_494,al_c,q_90,usm_0.66_1.00_0.01/3524f7_7b539889408d408f865aab840eff6b30.webp"
            />
          </div>
          <div>
            <img
              style={{ width: "100%", maxHeight: "560px", objectFit: "cover" }}
              alt="1"
              src="https://static.wixstatic.com/media/3524f7_1d8d412029b34335bb9ee37d4d7bfe5f.jpg/v1/fill/w_878,h_494,al_c,q_90,usm_0.66_1.00_0.01/3524f7_1d8d412029b34335bb9ee37d4d7bfe5f.webp"
            />
          </div>
          <div>
            <img
              style={{ width: "100%", maxHeight: "560px", objectFit: "cover" }}
              alt="1"
              src="https://static.wixstatic.com/media/3524f7_bfa7339efd36424eb4d2edc76fa0b435.jpg/v1/fill/w_878,h_494,al_c,q_90,usm_0.66_1.00_0.01/3524f7_bfa7339efd36424eb4d2edc76fa0b435.webp"
            />
          </div>
          <div>
            <img
              style={{ width: "100%", maxHeight: "560px", objectFit: "cover" }}
              alt="1"
              src="https://static.wixstatic.com/media/3524f7_d3946fcad3b542b4aa3947df2a3b63aa~mv2_d_3888_2592_s_4_2.jpg/v1/fill/w_741,h_494,al_c,q_90,usm_0.66_1.00_0.01/3524f7_d3946fcad3b542b4aa3947df2a3b63aa~mv2_d_3888_2592_s_4_2.webp"
            />
          </div>
          <div>
            <img
              style={{ width: "100%", maxHeight: "560px", objectFit: "cover" }}
              alt="1"
              src="https://static.wixstatic.com/media/3524f7_9bfb9ea00ddb481f939c9083bc1e0927~mv2_d_3888_2592_s_4_2.jpg/v1/fill/w_741,h_494,al_c,q_90,usm_0.66_1.00_0.01/3524f7_9bfb9ea00ddb481f939c9083bc1e0927~mv2_d_3888_2592_s_4_2.webp"
            />
          </div>
          <div>
            <img
              style={{ width: "100%", maxHeight: "560px", objectFit: "cover" }}
              alt="1"
              src="https://static.wixstatic.com/media/3524f7_8880bdd3cc134ffa8a0790599aacfc17~mv2_d_3888_2592_s_4_2.jpg/v1/fill/w_741,h_494,al_c,q_90,usm_0.66_1.00_0.01/3524f7_8880bdd3cc134ffa8a0790599aacfc17~mv2_d_3888_2592_s_4_2.webp"
            />
          </div>
          <div>
            <img
              style={{ width: "100%", maxHeight: "560px", objectFit: "cover" }}
              alt="1"
              src="https://static.wixstatic.com/media/3524f7_f66c6e133662454c93cc9bbaabd41b50.jpg/v1/fill/w_741,h_494,al_c,q_90,usm_0.66_1.00_0.01/3524f7_f66c6e133662454c93cc9bbaabd41b50.webp"
            />
          </div>
        </Slider>
        <Row align="middle" className="feature-properin">
          <Col md={6} align="center">
            <Typography className="text-card-section1" data-aos="fade-down">
              26
            </Typography>
            <Typography style={{ fontSize: "1.2vw" }}>KLIEN</Typography>
          </Col>
          <Col md={6} align="center">
            <Typography className="text-card-section1" data-aos="fade-down">
              27
            </Typography>
            <Typography style={{ fontSize: "1.2vw" }}>KARYAWAN</Typography>
          </Col>
          <Col md={6} align="center">
            <Typography className="text-card-section1" data-aos="fade-down">
              12,5 M
            </Typography>
            <Typography style={{ fontSize: "1.2vw" }}>DANA</Typography>
          </Col>
          <Col md={6} align="center">
            <Typography className="text-card-section1" data-aos="fade-down">
              6 M
            </Typography>
            <Typography style={{ fontSize: "1.2vw" }}>UANG</Typography>
          </Col>
        </Row>
        <Row className="row-sect1" justify="center">
          <Col md={8} align="left" justify="center">
            <Typography className="prop-about">
              Pindai Media Komunika
            </Typography>
          </Col>
          <Col md={16} align="left">
            <Typography style={{ fontSize: "1.2vw", color: "white" }}>
              Pindai Media Komunika memberikan layanan tanpa batas kepada klien
              terbatas. Layanan tersebut disesuaikan dengan kebutuhan
              masing-masing klien. Media Monitoring Online, membantu
              perusahaan/institusi/organisasi memonitoring pemberitaan yang anda
              butuhkan baik media cetak, elektronik, situs berita maupun
              jejaring sosial. Kami akan laporkan secara realtime melalu situs
              yang dibuat khusus untuk kepentingan klien.
            </Typography>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Section1;
