import React, { Component } from "react";
import { Menu } from "antd";

class RightMenuMobile extends Component {
  render() {
    return (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="280"
          height="20"
          viewBox="0 0 286 26"
          style={{ marginTop: 10 }}
        >
          <text
            id="Pindai_Communications"
            data-name="Pindai Communications"
            transform="translate(0 21)"
            fill="#fff"
            fontSize="19"
            fontFamily="SFMono-Medium, SF Mono"
            fontWeight="500"
          >
            <tspan x="0" y="0">
              Pindai Communications
            </tspan>
          </text>
        </svg>
        <Menu defaultOpenKeys={["sub1"]} mode="inline">
          <Menu.Item key="0">
            <a href="/" style={{ color: "white", fontSize: "4.5vw" }}>
              Beranda
            </a>
          </Menu.Item>
          <Menu.Item key="1">
            <a href="/admin" style={{ color: "white", fontSize: "4.5vw" }}>
              Admin
            </a>
          </Menu.Item>
          <Menu.Item key="2">
            <a href="/kemenperin" style={{ color: "white", fontSize: "4.5vw" }}>
              Kemenperin
            </a>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default RightMenuMobile;
