import React, { Component } from "react";
import { Row, Col, Typography } from "antd";
import AOS from "aos";

const { Title } = Typography;

const ornament = require("../../images/ornament.png");
const invest1 = require("../../images/invest1.png");
const invest2 = require("../../images/invest2.svg");
const invest3 = require("../../images/invest3.svg");
const invest4 = require("../../images/invest4.svg");
const logos1 = require("../../images/logos-1.svg");

class Section2 extends Component {
  state = {
    width: window.innerWidth,
    visible: false,
    confirmLoading: false
  };
  componentDidMount = () => {
    AOS.init({
      duration: 1000,
      delay: 50
    });
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = () => {
    this.setState({
      confirmLoading: true
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false
      });
    }, 2000);
  };
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  render() {
    return (
      <div>
        <Row className="section2" justify="center">
          <Col
            data-aos="slide-right"
            data-aos-delay="550"
            md={24}
            align="center"
            style={{ marginBottom: "2rem" }}
          >
            <Title
              level={4}
              className="text-white"
              style={{ fontSize: "1.9vw" }}
            >
              Mengapa Harus Pindai ?
            </Title>
            <img src={ornament} />
          </Col>
          <Row
            type="flex"
            gutter={48}
            justify="space-around"
            data-aos="slide-right"
          >
            <Col md={6} align="center">
              <img src={invest1} className="img1" />
              <Title level={4} className="text-white">
                Strategi
              </Title>
              <Typography style={{ fontSize: "1.2vw" }}>
                Merencanakan program dan strategi komunikasi untuk membangun
                reputasi perusahaan dan memberikan nilai tambah bagi brand
                korporasi.
              </Typography>
            </Col>
            <Col md={6} align="center">
              <img src={invest2} className="img2" />
              <Title level={4} className="text-white">
                Memahami publik
              </Title>
              <Typography style={{ fontSize: "1.2vw" }}>
                Membantu klien mengidentifikasi kelebihan korporasi serta
                memahami publik serta stackholdernya.
              </Typography>
            </Col>
            <Col md={6} align="center">
              <img src={invest3} className="img3" />
              <Title level={4} className="text-white">
                Kuat
              </Title>
              <Typography style={{ fontSize: "1.2vw" }}>
                Mampu menggunakan kekuatan pers serta media komunikasi lainnya
                untuk mempengaruhi target audience tersebut.
              </Typography>
            </Col>
            <Col md={6} align="center">
              <img src={invest4} className="img4" />
              <Title level={4} className="text-white">
                PR Agency
              </Title>
              <Typography style={{ fontSize: "1.2vw" }}>
                Lebih dari hanya sebuah Public Relation Agency.
              </Typography>
            </Col>
          </Row>
        </Row>
        <Row className="section2" justify="center">
          <Col
            data-aos="flip-left"
            data-aos-delay="550"
            md={24}
            align="center"
            style={{ marginBottom: "2.2rem" }}
          >
            <Title
              level={4}
              className="text-white"
              style={{ fontSize: "1.9vw" }}
            >
              Layanan
            </Title>
            <Typography style={{ fontSize: "1.2vw" }}>
              Kami siap melayani anda untuk bidang media dan kehumasan ataupun
              yang secara profesional bisa kami pertanggung jawabkan.
            </Typography>
          </Col>
          <Row
            type="flex"
            gutter={48}
            justify="space-around"
            data-aos="flip-left"
          >
            <Col md={8} align="center">
              <img
                src="https://static.wixstatic.com/media/3524f7_c7c27fe0383044f6ac549b623aac7d9e.jpg/v1/fill/w_281,h_220,al_c,q_80,usm_0.66_1.00_0.01/3524f7_c7c27fe0383044f6ac549b623aac7d9e.webp"
                className="img1"
              />
              <Title level={4} className="text-white">
                MeMo
              </Title>
              <Typography style={{ fontSize: "1.2vw" }}>
                Media Monitoring Online, membantu
                perusahaan/institusi/organisasi memonitoring pemberitaan yang
                anda butuhkan baik media cetak, elektronik, situs berita maupun
                jejaring sosial. Kami akan laporkan secara realtime melalu situs
                yang dibuat khusus untuk kepentingan klien.
              </Typography>
            </Col>
            <Col md={8} align="center">
              <img
                src="https://static.wixstatic.com/media/3524f7_c775c6e0bd17490190b1ac0e2a643be5.jpg/v1/fill/w_281,h_220,al_c,q_80,usm_0.66_1.00_0.01/3524f7_c775c6e0bd17490190b1ac0e2a643be5.webp"
                className="img3"
              />
              <Title level={4} className="text-white">
                Media Internal
              </Title>
              <Typography style={{ fontSize: "1.2vw" }}>
                Tenaga jurnalis kami yang berdedikasi tinggi siap membantu
                mewujudkan majalah, newsletter internal yang Anda harapkan. Kami
                akan siapkan dengan kualitas media yang sesungguhnya baik dari
                sisi penulisan maupun perwajahan.
              </Typography>
            </Col>
            <Col md={8} align="center">
              <img
                src="https://static.wixstatic.com/media/3524f7_c0f34e5ee1a343de8900ac682a5794ed.jpg/v1/fill/w_281,h_220,al_c,q_80,usm_0.66_1.00_0.01/3524f7_c0f34e5ee1a343de8900ac682a5794ed.webp"
                className="img4"
              />
              <Title level={4} className="text-white">
                MICE
              </Title>
              <Typography style={{ fontSize: "1.2vw" }}>
                Apapun kebutuhan Anda terkait dengan urusan media dan kehumasan,
                Pindai Media Komunika siap melayani. Mulai dari media relasion,
                konferensi Pers, Media Visit, Media Training hingga Government
                Relasion.
              </Typography>
            </Col>
          </Row>
        </Row>
        <Row className="section2" justify="center">
          <Col
            data-aos="flip-left"
            data-aos-delay="550"
            md={24}
            align="center"
            style={{ marginBottom: "2.2rem" }}
          >
            <Title
              level={4}
              className="text-white"
              style={{ fontSize: "1.9vw" }}
            >
              KLIEN
            </Title>
            <Typography style={{ fontSize: "1.2vw" }}>
              Sejak berdiri 2007 lalu, Pindai Media Komunika telah melayani
              banyak perusahaan terkemuka di antaranya :
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <img
              data-aos="zoom-in-down"
              data-aos-delay="50"
              src={logos1}
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-down"
              data-aos-delay="250"
              src="https://static.wixstatic.com/media/3524f7_eda00642dc8446058efbed2a471e48bd.png/v1/fill/w_235,h_74,al_c,q_95/3524f7_eda00642dc8446058efbed2a471e48bd.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-down"
              data-aos-delay="350"
              src="https://static.wixstatic.com/media/3524f7_95318532df924d349868fd93f4bf8f40.png/v1/fill/w_235,h_74,al_c,q_95/3524f7_95318532df924d349868fd93f4bf8f40.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-down"
              data-aos-delay="450"
              src="https://static.wixstatic.com/media/3524f7_811c50c87e6b4c688b28c69f6d4150f6~mv2.png/v1/fill/w_401,h_360,al_c,q_95/3524f7_811c50c87e6b4c688b28c69f6d4150f6~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-down"
              data-aos-delay="550"
              src="https://static.wixstatic.com/media/3524f7_49a69e9d1d5644c7bf3e48bdaf12dee4~mv2.png/v1/fill/w_1126,h_489,al_c,q_95,usm_0.66_1.00_0.01/3524f7_49a69e9d1d5644c7bf3e48bdaf12dee4~mv2.webp  "
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-down"
              data-aos-delay="650"
              src="https://static.wixstatic.com/media/3524f7_e7dd41bb2a53466da5d55d387ff9e07c~mv2.png/v1/fill/w_285,h_85,al_c,q_95/3524f7_e7dd41bb2a53466da5d55d387ff9e07c~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: 70,
            paddingLeft: 70
          }}
        >
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="650"
              src="https://static.wixstatic.com/media/3524f7_62f69ae424c3407fba942855566c393b~mv2.png/v1/fill/w_1126,h_442,al_c,q_95,usm_0.66_1.00_0.01/3524f7_62f69ae424c3407fba942855566c393b~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="550"
              src="https://static.wixstatic.com/media/3524f7_848ba6196aad4c5e81e104b2860b182e~mv2.png/v1/fill/w_709,h_244,al_c,q_95/3524f7_848ba6196aad4c5e81e104b2860b182e~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="450"
              src="https://static.wixstatic.com/media/3524f7_853bcf43457d46cd86d95cfdbf2882be~mv2.png/v1/fill/w_296,h_171,al_c,q_95/3524f7_853bcf43457d46cd86d95cfdbf2882be~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="350"
              src="https://static.wixstatic.com/media/3524f7_f9db16cda2de4289881f5e573d8cb9e0~mv2.png/v1/fill/w_326,h_94,al_c,q_95/3524f7_f9db16cda2de4289881f5e573d8cb9e0~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="250"
              src="https://static.wixstatic.com/media/3524f7_a89086f9fa234453a8e57f1bdcd2256b~mv2.png/v1/fill/w_235,h_74,al_c,q_95/3524f7_a89086f9fa234453a8e57f1bdcd2256b~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="50"
              src="https://static.wixstatic.com/media/3524f7_4603344c15754c459bcc79cd30de0172~mv2.png/v1/fill/w_225,h_225,al_c,q_95/3524f7_4603344c15754c459bcc79cd30de0172~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: 70,
            marginBottom: 150,
            paddingLeft: 70
          }}
        >
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="650"
              src="https://static.wixstatic.com/media/3524f7_bb5b5d305ab8464cba652298e4031f9a~mv2.png/v1/fill/w_245,h_205,al_c,q_95/3524f7_bb5b5d305ab8464cba652298e4031f9a~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="550"
              src="https://static.wixstatic.com/media/3524f7_b5886e380f4e470f9ee12e76066e2445~mv2.png/v1/fill/w_457,h_110,al_c,q_95/3524f7_b5886e380f4e470f9ee12e76066e2445~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="450"
              src="https://static.wixstatic.com/media/3524f7_853bcf43457d46cd86d95cfdbf2882be~mv2.png/v1/fill/w_296,h_171,al_c,q_95/3524f7_853bcf43457d46cd86d95cfdbf2882be~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="350"
              src="https://static.wixstatic.com/media/3524f7_df7f644ba194469180763fa5a7b83185~mv2.png/v1/fill/w_425,h_315,al_c,q_95/3524f7_df7f644ba194469180763fa5a7b83185~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="250"
              src="https://static.wixstatic.com/media/3524f7_a59a1aa1275f465d93ea50fc080b1b19~mv2.png/v1/fill/w_296,h_171,al_c,q_95/3524f7_a59a1aa1275f465d93ea50fc080b1b19~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
          <Col md={4}>
            <img
              data-aos="zoom-in-up"
              data-aos-delay="50"
              src="https://static.wixstatic.com/media/3524f7_4f5807bf0366401ba048a9b575b40d7e~mv2.png/v1/fill/w_284,h_177,al_c,q_95/3524f7_4f5807bf0366401ba048a9b575b40d7e~mv2.webp"
              style={{ maxHeight: 48 }}
            ></img>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Section2;
