import React, { Component } from "react";
import { Menu } from "antd";

import {
  LinkedinFilled,
  InstagramFilled,
  FacebookFilled,
} from "@ant-design/icons";

class RightMenu extends Component {
  render() {
    return (
      <Menu mode="horizontal">
        <Menu.Item key="0">
          <a href="/">Beranda</a>
        </Menu.Item>
        <Menu.Item key="1">
          <a href="/admin">Admin</a>
        </Menu.Item>
        <Menu.Item key="2">
          <a href="/kemenperin">Kemenperin</a>
        </Menu.Item>
        <Menu.Item key="4">
          <a href="" target="_blank">
            <LinkedinFilled style={{ fontSize: "1.4vw" }} />
          </a>
        </Menu.Item>
        <Menu.Item key="5">
          <a href="" target="_blank">
            <InstagramFilled style={{ fontSize: "1.4vw" }} />
          </a>
        </Menu.Item>
        <Menu.Item key="6">
          <a href="" target="_blank">
            <FacebookFilled style={{ fontSize: "1.4vw" }} />
          </a>
        </Menu.Item>
      </Menu>
    );
  }
}

export default RightMenu;
