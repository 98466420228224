import React, { Component } from "react";
import RightMenuMobile from "./RightMenuMobile";
import { Drawer, Button } from "antd";

class NavbarMobile extends Component {
  state = {
    current: "sub1",
    visible: false
  };
  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  render() {
    return (
      <nav className="menuBar">
        <div className="logo">
          <a href="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="286"
              height="26"
              viewBox="0 0 286 26"
            >
              <text
                id="Pindai_Communications"
                data-name="Pindai Communications"
                transform="translate(0 21)"
                fill="#23ad46"
                fontSize="20"
                fontFamily="SFMono-Medium, SF Mono"
                fontWeight="500"
              >
                <tspan x="0" y="0">
                  Pindai Communications
                </tspan>
              </text>
            </svg>
          </a>
        </div>
        <div className="menuCon">
          <Button className="barsMenu" type="primary" onClick={this.showDrawer}>
            <span className="barsBtn"></span>
          </Button>
          <Drawer
            style={{ color: "#23ad46" }}
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <RightMenuMobile />
          </Drawer>
        </div>
      </nav>
    );
  }
}

export default NavbarMobile;
