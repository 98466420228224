import React, { Component } from "react";
import { BackTop } from "antd";
import Footer from "./components/Footer";
import FooterMobile from "./components/FooterMobile";
import Navbar from "./components/Navbar";
import NavbarMobile from "./components/NavbarMobile";
import Section1 from "./components/Beranda/Section1";
import Section1Mobile from "./components/Beranda/Section1Mobile";
import Section2 from "./components/Beranda/Section2";
import Section2Mobile from "./components/Beranda/Section2Mobile";
import AOS from "aos";
import "aos/dist/aos.css";
import "antd/dist/antd.css";
import "./App.scss";

class App extends Component {
  state = {
    width: window.innerWidth,
    visible: false,
    confirmLoading: false
  };
  componentDidMount = () => {
    AOS.init({
      duration: 1000,
      delay: 50
    });
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = () => {
    this.setState({
      confirmLoading: true
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false
      });
    }, 2000);
  };
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  UNSAFE_componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  render() {
    const { width } = this.state;
    const isMobile = width <= 500;
    //untuk pc//
    if (!isMobile) {
      return (
        <div>
          <Navbar></Navbar>
          <Section1 />
          <Section2 />
          <Footer />
          <BackTop visibilityHeight={450} />
        </div>
      );
    } else {
      //untuk mobile//
      return (
        <div>
          <NavbarMobile></NavbarMobile>
          <Section1Mobile />
          <Section2Mobile />
          <FooterMobile />
          <BackTop visibilityHeight={450} />
        </div>
      );
    }
  }
}

export default App;
